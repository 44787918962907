import { useEffect, useState } from "react";
import classes from "./Heart.module.css";

const Heart = (props) => {
  const [cls, setCls] = useState([classes.Heart]);

  useEffect(() => {
    setCls([classes.Heart, props.className]);
  }, [props.className]);

  const click = () => {
    const tmp = [...cls];
    tmp.push(classes.Pulsing);

    const activeIndex = tmp.indexOf(classes.Active);

    if (activeIndex > -1) {
      tmp.splice(activeIndex, 1);
    } else {
      tmp.push(classes.Active);
    }

    setCls(tmp);
    setTimeout(() => {
      const noPulse = tmp.filter((c) => c !== classes.Pulsing);
      setCls(noPulse);
    }, 1000);
  };

  return (
    <svg onClick={click} className={cls.join(" ")} viewBox="0 0 32 29.6">
      <path d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"></path>
    </svg>
  );
};

export default Heart;
