const Profile = () => {
  return (
    <svg
      viewBox="0 0 483 468"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    >
      <g transform="matrix(1,0,0,1,-159.41,-60.4903)">
        <g transform="matrix(1.22,0,0,1.22,27.9,-92.8)">
          <path
            d="M184.508,356.393C184.508,356.393 119.795,404.663 122.213,494.098L488.607,494.098C481.767,349.711 364.836,321.967 364.836,321.967C363.065,322.471 337.879,339.339 305,340C249.808,340 205,295.192 205,240C205,184.808 249.808,140 305,140C360.192,140 405,184.808 405,240C405,295.192 360.192,340 305,340"
            style={{ strokeWidth: "15px" }}
          />
        </g>
      </g>
    </svg>
  );
};

export default Profile;
