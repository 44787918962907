import classes from "./Quote.module.css";

const Quote = (props) => {
  return (
    <article className={classes.Quote}>
      <div className={classes.Text}>
        <p>{props.children}</p>
        <span>{props.author}</span>
      </div>
      <div className={classes.Image} style={{ backgroundImage: `url(${props.image})` }}></div>
    </article>
  );
};

export default Quote;
