import classes from "./Products.module.css";

import ProductTile from "../../Components/ProductTile/ProductTile";

import qs from "qs";

import { useParams } from "react-router";
import { useEffect, useState } from "react";

import axios, { urlPrefix } from "../../axios";
import Button from "../../Components/Button/Button";

const Products = (props) => {
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalAmount, setTotalAmount] = useState(1);
  const [elementsPerPage] = useState(15);
  const [filter, setFilter] = useState({});

  const { action, category, subcategory } = useParams();

  useEffect(() => {
    if (action === "produkte" && category === "alle") {
      setFilter({});
    } else if (action === "produkte") {
      setFilter({
        $and: [
          {
            categories: {
              link: {
                $eq: subcategory,
              },
            },
          },
          {
            main_category: {
              link: {
                $eq: category,
              },
            },
          },
        ],
      });
    } else if (action === "suche") {
      setFilter({
        $or: [
          {
            title: {
              $containsi: category,
            },
          },
          {
            subtitle: {
              $containsi: category,
            },
          },
        ],
      });
    }
    setPage(1);
  }, [category, subcategory, action]);

  useEffect(() => {
    const query = qs.stringify({
      filters: filter,
      pagination: {
        pageSize: elementsPerPage,
        page: 1,
      },
      populate: "thumbnail,categories,main_category",
    });

    axios.get(`/api/products?${query}`).then((res) => {
      setProducts(res.data.data);
      setTotalAmount(res.data.meta.pagination.total);
    });
  }, [filter, elementsPerPage]);

  const loadMore = () => {
    const query = qs.stringify({
      filters: filter,
      pagination: {
        pageSize: elementsPerPage,
        page: page + 1,
      },
      populate: "thumbnail,categories,main_category",
    });

    axios.get(`/api/products?${query}`).then((res) => {
      setPage(page + 1);
      setProducts([...products, ...res.data.data]);
    });
  };

  return (
    <main className={classes.Products}>
      <section style={{ flexGrow: 1 }}>
        <h2 className={classes.Title}>
          {action === "suche" ? `Suche nach: '${category}'` : category}
        </h2>

        <section className={classes.ProductList}>
          {products.map((p, i) => (
            <ProductTile
              key={i}
              category={p?.attributes?.main_category?.data?.attributes}
              subcategory={p?.attributes?.categories?.data[0]?.attributes}
              productId={p?.id}
              discount={p?.attributes?.discount}
              image={
                urlPrefix + p?.attributes?.thumbnail?.data?.attributes?.url
              }
              title={p?.attributes?.title}
              subtitle={p?.attributes?.subtitle}
              basePrice={p?.attributes?.baseprice}
              discountPrice={p?.attributes?.discountprice}
            />
          ))}
        </section>
        <p className={classes.MetaInfo}>
          {products.length} von {totalAmount} Produkte
        </p>
        {totalAmount > products.length ? (
          <Button
            style={{ textAlign: "center", marginTop: 20 }}
            onClick={loadMore}
          >
            Mehr anzeigen
          </Button>
        ) : null}
      </section>
    </main>
  );
};

export default Products;
