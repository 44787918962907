import Header from "./Layout/Header/Header";
import Footer from "./Layout/Footer/Footer";
import Home from "./Pages/Home/Home";
import { Route, Routes } from "react-router";
import Products from "./Pages/Products/Products";
import Details from "./Pages/Details/Details";
import Registration from "./Pages/User/Registration/Registration";
import Profile from "./Pages/User/Profile/Profile";
import Login from "./Pages/User/Login/Login";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Navigation from "./Layout/Navigation/Navigation";
import { useState } from "react";

const theme = createTheme({
  palette: {
    primary: {
      main: "#73ac86",
    },
  },
});

window.urlifyString = (s) => {
  let tmp = s.toLowerCase();
  tmp = tmp.replaceAll(" - ", "-");
  tmp = tmp.replaceAll(" ", "-");
  tmp = tmp.replaceAll("'", "");
  return tmp;
};

const App = (props) => {
  const [navOpen, setNavOpen] = useState(false);

  const toggleNavOpen = () => {
    console.log("lol");
    setNavOpen(!navOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Header navOpen={navOpen} toggleNavOpen={toggleNavOpen} />
        <section className="Main">
          <aside className={`NavSection${navOpen ? " Open" : ""}`}>
            <Routes>
              <Route
                path="/:action/:category/:subcategory"
                element={
                  <Navigation toggleNavOpen={toggleNavOpen} navOpen={navOpen} />
                }
              />

              <Route
                path="/produkte/:category/:subcategory/:product"
                element={
                  <Navigation toggleNavOpen={toggleNavOpen} navOpen={navOpen} />
                }
              />

              <Route
                path="/:action/:category/"
                element={
                  <Navigation toggleNavOpen={toggleNavOpen} navOpen={navOpen} />
                }
              />

              <Route
                path="/"
                element={
                  <Navigation toggleNavOpen={toggleNavOpen} navOpen={navOpen} />
                }
              />
            </Routes>
          </aside>
          <section className="MainSection">
            <Routes>
              <Route path="/" element={<Home />} />

              <Route path="/benutzer/registration" element={<Registration />} />
              <Route path="/benutzer/profil" element={<Profile />} />
              <Route path="/benutzer/login" element={<Login />} />

              <Route path="/:action/:category" element={<Products />} />
              <Route
                path="/:action/:category/:subcategory"
                element={<Products />}
              />
              <Route
                path="/produkte/:category/:subcategory/:product"
                element={<Details />}
              />
            </Routes>
          </section>
        </section>
        <Footer />
      </div>
    </ThemeProvider>
  );
};

export default App;
