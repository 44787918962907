import { Link } from "react-router-dom";
import classes from "./SearchResult.module.css";

const SearchResult = (props) => {
  return (
    <Link
      onClick={props.resetTerm}
      className={classes.Link}
      to={`/produkte/${props.category}/${props.subcategory}/${
        props.productId
      }-${window.urlifyString(props.title)}`}
    >
      <article className={classes.SearchResult}>
        <figure>
          <img src={props.image} alt={props.title} />
        </figure>
        <div>
          <p>{props.title}</p>
          <p>
            {props.discountPrice ? (
              <span className={classes.DiscountPrice}>{"CHF " + props.discountPrice?.toFixed(2)}</span>
            ) : null}
            <span className={classes.Price}>{"CHF " + props.price?.toFixed(2)}</span>
          </p>
        </div>
      </article>
    </Link>
  );
};

export default SearchResult;
