import classes from "./Button.module.css";

const Button = (props) => {
  return (
    <div style={props.style} className={props.className}>
      <button onClick={props.onClick} className={classes.Button}>
        <span>{props.children}</span>
      </button>
    </div>
  );
};

export default Button;
