import { Link } from "react-router-dom";
import classes from "./Slide.module.css";

const Slide = (props) => {
  const cls = [classes.Slide];
  if (props.first === true) {
    cls.push(classes.First);
  }

  if (props.discount) {
    cls.push(classes.Discount);
  }

  return (
    <Link
      className={classes.Link}
      to={`/produkte/${props.category?.link}/${props.subcategory?.link}/${
        props.productId
      }-${window.urlifyString(props.title)}`}
    >
      <article className={cls.join(" ")}>
        <p className={classes.Percentage}>{props.discount}%</p>
        <div className={classes.Image}>
          <img src={props.image} alt={props.title} />
        </div>
        <div className={classes.Price}>
          {props.discountPrice ? (
            <span className={classes.PriceOld}>
              CHF {props.basePrice.toFixed(2)}
            </span>
          ) : null}
          <p>
            CHF{" "}
            {props.discountPrice
              ? props.discountPrice.toFixed(2)
              : props.basePrice.toFixed(2)}
          </p>
        </div>
        <p className={classes.Title}>{props.title}</p>
        <p className={classes.Subtitle}>{props.subtitle}</p>
      </article>
    </Link>
  );
};

export default Slide;
