import { Link } from "react-router-dom";
import classes from "./Promotion.module.css";

const Promotion = (props) => {
  const cls = [classes.Promotion];

  if (props.className) {
    cls.push(props.className);
  }

  return (
    <Link draggable={false} to={props.to} className={cls.join(" ")}>
      <div
        className={classes.Image}
        style={{ backgroundImage: `url(${props.image})` }}
      ></div>
      <p className={classes.Large}>{props.title}</p>
      <p>{props.subtitle}</p>
      <span></span>
    </Link>
  );
};

export default Promotion;
