import qs from "qs";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router";
import axios from "../../axios";
import classes from "./Navigation.module.css";

const Navigation = (props) => {
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  const { action, category, subcategory } = useParams();

  useEffect(() => {
    const query = qs.stringify({
      filters: {
        category: {
          title: {
            $null: true,
          },
        },
      },
    });

    axios.get(`/api/categories?${query}`).then((res) => {
      setCategories(res.data.data);
    });
  }, []);

  useEffect(() => {
    if (action !== "suche") {
      const subCatQuery = qs.stringify({
        filters: {
          category: {
            link: category,
          },
        },
      });

      axios.get(`/api/categories?${subCatQuery}`).then((res) => {
        setSubcategories(res.data.data);
      });
    }
  }, [category, subcategory, action]);

  let cls = [];
  if (props.navOpen) {
    cls = [classes.Navigation, classes.Open];
  } else {
    cls = [classes.Navigation];
  }

  return (
    <nav className={cls.join(" ")}>
      <section className={classes.Inner}>
        <ul>
          <li>
            <NavLink onClick={props.toggleNavOpen} to={`/produkte/alle`}>
              Alle Produkte
            </NavLink>
          </li>
          {categories.map((c) => (
            <li key={c.id}>
              <NavLink
                onClick={props.toggleNavOpen}
                to={`/produkte/${c.attributes.link}`}
              >
                {c.attributes.title}
              </NavLink>
              {c.attributes.link === category ? (
                <ul className={classes.SubNav}>
                  {subcategories.map((s) => (
                    <li key={s.id}>
                      <NavLink
                        onClick={props.toggleNavOpen}
                        to={`/produkte/${c.attributes.link}/${s.attributes.link}`}
                      >
                        {s.attributes.title}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              ) : null}
            </li>
          ))}
        </ul>
      </section>
    </nav>
  );
};

export default Navigation;
