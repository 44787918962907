import Promotion from "../../Components/Promotion/Promotion";
import Quote from "../../Components/Quote/Quote";
import Slider from "../../Components/Slider/Slider";

import quoteTree from "../../Components/Quote/tree.jpg";
import promoNecklace from "../../Components/Promotion/necklace.jpg";
import promoCrystal from "../../Components/Promotion/crystal.jpg";

import classes from "./Home.module.css";
import { useEffect, useState } from "react";
import axios from "../../axios";
import qs from "qs";

const Home = (props) => {
  const [newProducts, setNewProducts] = useState([]);
  const [discountProducts, setDiscountProducts] = useState([]);

  useEffect(() => {
    const newProductsQuery = qs.stringify({
      sort: "createdAt:DESC",
      pagination: {
        pageSize: 10,
        page: 1,
      },
      populate: "thumbnail,categories,main_category",
    });

    axios.get(`/api/products?${newProductsQuery}`).then((res) => {
      setNewProducts(res.data.data);
    });

    const discountProductsQuery = qs.stringify({
      sort: "createdAt:DESC",
      filters: {
        discountprice: {
          $notNull: true,
        },
      },
      pagination: {
        pageSize: 15,
        page: 1,
      },
      populate: "thumbnail,categories,main_category",
    });

    axios.get(`api/products?${discountProductsQuery}`).then((res) => {
      setDiscountProducts(res.data.data);
    });
  }, []);

  return (
    <main>
      <Quote image={quoteTree} author="Mario Mantese">
        Wer Totalität ist, ist ein Schattentilger, der nicht mitleidet, sondern
        durch sein Hiersein Leiden beendet
      </Quote>

      <Slider data={newProducts} title="Neu eingetroffen" />

      <section className={classes.PromotionsContainer}>
        <Promotion
          className={classes.Promotion}
          to="/produkte/schmuck"
          title="Entdecke"
          subtitle="unsere Schmuckauswahl"
          image={promoNecklace}
        />
        <Promotion
          className={classes.Promotion}
          to="/produkte/rohmaterialien"
          title="Rohmaterial"
          subtitle="Ganz nach deinen Wünschen"
          image={promoCrystal}
        />
      </section>

      <Slider data={discountProducts} title="Aktuelle Aktionen" />
    </main>
  );
};

export default Home;
