import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../../axios";

const Profile = (props) => {
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("/api/users/me?populate=rating", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("STAuth")}`,
        },
      })
      .then((res) => {
        setUserData(res.data);
      })
      .catch(() => {
        localStorage.removeItem("STAuth");
        navigate("/");
      });
  }, [navigate]);

  const logout = () => {
    localStorage.removeItem("STAuth");
    navigate("/");
  };

  return (
    <main>
      <h1>
        Willkommen {userData.firstname} {userData.lastname}
      </h1>
      <Button variant="contained" onClick={logout}>Logout</Button>
    </main>
  );
};

export default Profile;
