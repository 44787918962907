import { Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "../../../axios";
import classes from "./Registration.module.css";

const { Formik } = require("formik");

const Registration = (props) => {
  const navigate = useNavigate();

  const submitForm = (values) => {
    axios
      .post("/api/auth/local/register", {
        firstname: values.firstName,
        lastname: values.lastName,
        username: values.email,
        email: values.email,
        password: values.password,
        confirmed: false,
      })
      .then((response) => {
        localStorage.setItem("STAuth", response.data.jwt);
        navigate("/benutzer/profil");
      })
      .catch((error) => {
        console.log("An error occurred:", error.response);
      });
  };

  return (
    <main className={classes.Registration}>
      <h1>Registration</h1>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: "",
        }}
        onSubmit={(values) => submitForm(values)}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form className={classes.Form} onSubmit={handleSubmit}>
            <TextField
              className={classes.Input}
              label="Vorname"
              name="firstName"
              value={values.firstName}
              onChange={handleChange}
              variant="outlined"
            />
            <TextField
              className={classes.Input}
              label="Nachname"
              name="lastName"
              value={values.lastName}
              onChange={handleChange}
              variant="outlined"
            />
            <TextField
              className={classes.Input}
              label="Email"
              name="email"
              value={values.email}
              onChange={handleChange}
              variant="outlined"
            />
            <TextField
              className={classes.Input}
              label="Passwort"
              type="password"
              name="password"
              value={values.password}
              onChange={handleChange}
              variant="outlined"
            />
            <Button type="submit" variant="contained">
              Registrieren
            </Button>
          </form>
        )}
      </Formik>
    </main>
  );
};

export default Registration;
