import classes from "./Header.module.css";
import Logo from "./Logo/Logo";

import Cart from "./Icons/Cart/Cart";
import Profile from "./Icons/Profile/Profile";
import Wishlist from "./Icons/Wishlist/Wishlist";
import SearchIcon from "./Icons/Search/Search";
import { Link } from "react-router-dom";
import Search from "./Search/Search";

const Header = (props) => {
  return (
    <section className={classes.Header}>
      <header>
        <section className={classes.Inner}>
          <Logo />
          <Search navOpen={props.navOpen} toggleNavOpen={props.toggleNavOpen} />
          <section className={classes.Controls}>
            <Link to="/benutzer/login">
              <Profile />
            </Link>
            <Wishlist />
            <Cart />
            <span className={classes.MobileOnly}>
              <SearchIcon />
            </span>
          </section>
        </section>
      </header>
    </section>
  );
};

export default Header;
