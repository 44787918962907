import { Button, TextField } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios, { urlPrefix } from "../../../axios";
import classes from "./Login.module.css";

const Login = (props) => {
  const navigation = useNavigate();
  const [strapiData, setStrapiData] = useState({});

  useEffect(() => {
    if (localStorage.getItem("STAuth")) {
      navigation("/benutzer/profil");
    }

    axios
      .get("/api/loginform?populate=background")
      .then((res) => {
        setStrapiData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [navigation]);

  const login = (values) => {
    axios
      .post("/api/auth/local", {
        identifier: values.email,
        password: values.password,
      })
      .then((res) => {
        localStorage.setItem("STAuth", res.data.jwt);
        navigation("/benutzer/profil");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <main
      style={{
        backgroundImage: `url(${urlPrefix}${strapiData?.attributes?.background?.data?.attributes?.url})`,
      }}
      className={classes.Login}
    >
      <Formik
        onSubmit={(values) => login(values)}
        initialValues={{
          email: "",
          password: "",
        }}
      >
        {({ values, handleSubmit, isSubmitting, handleChange }) => (
          <form className={classes.Form} onSubmit={handleSubmit}>
            <h1>{strapiData?.attributes?.title}</h1>
            <TextField
              className={classes.Input}
              label="Email"
              value={values.email}
              name="email"
              variant="outlined"
              onChange={handleChange}
            />
            <TextField
              className={classes.Input}
              label="Passwort"
              name="password"
              type="password"
              value={values.password}
              variant="outlined"
              onChange={handleChange}
            />
            <Button variant="contained" type="submit">
              Anmelden
            </Button>
            <Button style={{ width: "100%", marginTop: "20px" }}>
              <Link to="/benutzer/registration">Registrieren</Link>
            </Button>
          </form>
        )}
      </Formik>
    </main>
  );
};

export default Login;
