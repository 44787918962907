import { Link } from "react-router-dom";

import classes from "./Tags.module.css";

const Tags = (props) => (
  <section className={classes.Tags}>
    <Link
      className={classes.Tag}
      to={`/produkte/${props.mainCategory?.data?.attributes?.link}/`}
    >
      {props.mainCategory?.data?.attributes?.title}
    </Link>
    {props.categories?.data.map((c) => (
      <Link
        className={classes.Tag}
        key={c.id}
        to={`/produkte/${props.mainCategory?.data?.attributes?.link}/${c.attributes.link}`}
      >
        {c.attributes.title}
      </Link>
    ))}
  </section>
);

export default Tags;
