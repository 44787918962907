import classes from "./Burger.module.css";

const Burger = (props) => {
  return (
    <div onClick={props.onClick} className={classes.Burger}>
      <ul className={`${props.open ? classes.Open : null}`}>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <span>Menü</span>
    </div>
  );
};

export default Burger;
