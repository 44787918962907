const Wishlist = () => {
  return (
    <svg
      viewBox="0 0 294 271"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
    >
      <g transform="matrix(1,0,0,1,-262.5,-136.5)">
        <g transform="matrix(11.7084,0,0,11.7084,-8254.71,-31680.4)">
          <path
            d="M752,2724.5C752,2732.04 740,2740 740,2740C740,2740 728,2732.04 728,2724.5C728,2720.91 730.91,2718 734.5,2718C736.816,2718 738.849,2719.21 740,2721.03C741.151,2719.21 743.184,2718 745.5,2718C749.09,2718 752,2720.91 752,2724.5Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Wishlist;
