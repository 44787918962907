import QueryString from "qs";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios, { urlPrefix } from "../../../axios";
import Burger from "../../Navigation/Burger/Burger";
import classes from "./Search.module.css";
import SearchResult from "./SearchResult/SearchResult";

const Search = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchProducts, setSearchProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchOpen, setSearchOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const loadProducts = () => {
      const q = QueryString.stringify({
        filters: {
          $or: [
            {
              title: {
                $containsi: searchTerm,
              },
            },
            {
              subtitle: {
                $containsi: searchTerm,
              },
            },
          ],
        },
        populate: "main_category,categories,thumbnail",
        pagination: {
          page: 1,
          pageSize: 5,
        },
      });

      if (searchTerm.length > 0) {
        axios
          .get(`/api/products?${q}`)
          .then((res) => {
            setSearchProducts(res.data.data);
            setTotal(res.data.meta.pagination.total);
          })
          .catch((err) => console.log(err));
      } else {
        setSearchProducts([]);
      }
    };

    clearTimeout(window.timeout);
    window.timeout = setTimeout(() => {
      loadProducts();
      setSearchOpen(true);
    }, 300);
  }, [searchTerm]);

  const search = (e) => {
    e.preventDefault();
    setSearchOpen(false);
    if (searchTerm.length > 0) {
      navigate("/suche/" + searchTerm);
    }
  };

  const resetTerm = () => setSearchTerm("");

  const searchResClass = [classes.SearchResults];
  if (searchOpen) searchResClass.push(classes.Open);

  return (
    <div className={classes.Search}>
      <Burger open={props.navOpen} onClick={props.toggleNavOpen} />
      <form className={classes.Form} onSubmit={(e) => search(e)}>
        <input
          onFocus={() => setSearchOpen(true)}
          onBlur={() => setTimeout(() => setSearchOpen(false), 100)}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Suche..."
          type="text"
        />
      </form>
      {searchProducts.length > 0 ? (
        <section className={searchResClass.join(" ")}>
          {searchProducts.map((s) => (
            <SearchResult
              resetTerm={resetTerm}
              image={
                urlPrefix + s?.attributes?.thumbnail?.data?.attributes?.url
              }
              key={s.id}
              subtitle={s.attributes.subtitle}
              price={s.attributes.baseprice}
              discountPrice={s.attributes.discountprice}
              title={s.attributes.title}
              category={s.attributes.main_category.data.attributes.link}
              subcategory={s.attributes.categories.data[0].attributes.link}
              productId={s.id}
            />
          ))}

          {total > 5 ? (
            <Link className={classes.ShowAdditional} to={`suche/${searchTerm}`}>
              Alle {total} anzeigen...
            </Link>
          ) : null}
        </section>
      ) : null}
    </div>
  );
};

export default Search;
