import { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios, { urlPrefix } from "../../axios";
import classes from "./Details.module.css";

import "./SliderStyles.css";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper";
import Ratings from "./Ratings/Ratings";
import Slider from "../../Components/Slider/Slider";
import Tags from "./Tags/Tags";
import AddToCart from "./AddToCart/AddToCart";

const Details = (props) => {
  const { product } = useParams();

  const [productData, setProductData] = useState({});
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [amount, setAmount] = useState(1);
  const [similarProducts, setSimilarProducts] = useState([]);

  useEffect(() => {
    const productId = product.split("-")[0];

    const populate = [
      "ratings",
      "categories",
      "main_category",
      "images",
      "similar_products.thumbnail",
      "similar_products.main_category",
      "similar_products.categories",
    ];

    axios.get(`/api/products/${productId}?populate=${populate}`).then((res) => {
      setProductData(res.data.data);
      setSimilarProducts(res.data.data.attributes.similar_products.data);
    });
  }, [product]);

  return (
    <main>
      <section className={classes.Details}>
        <section className={classes.TitleMobile}>
          <h1 className={classes.Title}>{productData?.attributes?.title}</h1>
          <p className={classes.Subtitle}>
            {productData?.attributes?.subtitle}
          </p>
          <Tags
            mainCategory={productData?.attributes?.main_category}
            categories={productData?.attributes?.categories}
          />
        </section>
        <section className={classes.Media}>
          <section className={classes.Photos}>
            <Swiper
              spaceBetween={10}
              navigation={true}
              thumbs={{
                swiper:
                  thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
              }}
              modules={[FreeMode, Navigation, Thumbs]}
              className={`Slider ${classes.Slider}`}
            >
              {productData?.attributes?.images?.data.map((i) => (
                <SwiperSlide key={i.id}>
                  <img
                    key={i.id}
                    src={urlPrefix + i?.attributes?.url}
                    alt={i?.attributes?.title}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <Swiper
              onSwiper={setThumbsSwiper}
              spaceBetween={10}
              slidesPerView={4}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className={`SliderThumbs ${classes.SliderThumbs}`}
            >
              {productData?.attributes?.images?.data.map((i) => (
                <SwiperSlide key={i.id}>
                  <img
                    key={i.id}
                    src={urlPrefix + i?.attributes?.url}
                    alt={i?.attributes?.title}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </section>
        </section>
        <section className={classes.Info}>
          <section className={classes.Header}>
            <div className={classes.Titles}>
              <h1 className={classes.Title}>
                {productData?.attributes?.title}
              </h1>
              <p className={classes.Subtitle}>
                {productData?.attributes?.subtitle}
              </p>
              <Tags
                mainCategory={productData?.attributes?.main_category}
                categories={productData?.attributes?.categories}
              />
            </div>
            <AddToCart amount={amount} setAmount={(v) => setAmount(v)} />
          </section>
          <p className={classes.Description}>
            {productData?.attributes?.description}
          </p>
        </section>
      </section>
      {productData?.attributes?.ratings?.data.length > 0 ? (
        <Ratings ratings={productData?.attributes?.ratings?.data} />
      ) : null}

      {similarProducts.length > 0 ? (
        <Slider data={similarProducts} title="Ähnliche Produkte" />
      ) : null}
    </main>
  );
};

export default Details;
