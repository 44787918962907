import { Link } from "react-router-dom";
import logo from "./logo.svg";
import classes from "./Logo.module.css";

const Logo = (props) => {
  return (
    <Link className={classes.Logo} to="/">
      <img src={logo} alt="Logo" />
      <p>Schattentilger</p>
    </Link>
  );
};

export default Logo;
