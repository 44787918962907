import { useEffect, useState } from "react";
import Rating from "./Rating/Rating";

const Ratings = (props) => {
  const [prating, setPrating] = useState([]);

  useEffect(() => {
    if (props.ratings) setPrating(props.ratings);
  }, [props]);

  return (
    <section>
      <h1>Bewertungen</h1>
      {prating.map((r) => (
        <Rating
          key={r.id}
          title={r.attributes.title}
          rating={r.attributes.rating}
          description={r.attributes.description}
        />
      ))}
    </section>
  );
};

export default Ratings;
