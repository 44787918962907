const Rating = (props) => {
  return (
    <article>
      <h2>
        {props.title} - {props.rating}
      </h2>
      <p>{props.description}</p>
    </article>
  );
};

export default Rating;
