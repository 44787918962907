const Cart = () => {
  return (
    <svg
      viewBox="0 0 353 305"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
    >
      <g transform="matrix(1,0,0,1,-216.504,-103.5)">
        <g transform="matrix(12.1462,0,0,12.1462,-8595.22,-36887.2)">
          <path d="M747,3060C743.134,3060 740,3056.87 740,3053C740,3049.13 743.134,3046 747,3046C750.866,3046 754,3049.13 754,3053C754,3056.87 750.866,3060 747,3060ZM738,3048C735.791,3048 734,3049.79 734,3052L734,3054.99L738.228,3054.99C739.135,3059 742.714,3062 747,3062C747.68,3062 748.339,3061.92 748.976,3061.78L749.964,3068.11C750.051,3068.6 749.939,3069.05 749.635,3069.42C749.332,3069.8 748.927,3070 748.452,3070L727.549,3070C727.074,3070 726.669,3069.8 726.365,3069.42C726.062,3069.05 725.95,3068.6 726.037,3068.11L727.88,3056.29C728.018,3055.53 728.643,3054.99 729.392,3054.99L732,3054.99L732,3052C732,3048.69 734.686,3046 738,3046C738.951,3046 739.838,3046.24 740.637,3046.64C740.151,3047.12 739.728,3047.67 739.363,3048.25C738.936,3048.1 738.481,3048 738,3048ZM734,3055L732,3055L732,3057.28C731.405,3057.62 731,3058.26 731,3059C731,3060.11 731.895,3061 733,3061C734.105,3061 735,3060.11 735,3059C735,3058.26 734.595,3057.62 734,3057.28L734,3055Z" />
        </g>
      </g>
    </svg>
  );
};

export default Cart;
