import { Swiper, SwiperSlide } from "swiper/react";

import { Pagination } from "swiper";
import Slide from "./Slide/Slide";

import classes from "./Slider.module.css";

import "swiper/css";
import "swiper/css/pagination";
import { url } from "../../axios";

const Slider = (props) => {
  const urlPrefix = process.env.NODE_ENV === "production" ? "" : url;

  return (
    <section className={classes.Slider}>
      <h2 className={classes.Title}>{props.title}</h2>
      <Swiper
        spaceBetween={0}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        breakpoints={{
          0: {
            slidesPerView: 1.5,
          },
          800: {
            slidesPerView: 2.5,
          },
          1000: {
            slidesPerView: 3.5,
          },
          1200: {
            slidesPerView: 4.5,
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {props.data.map((s, i) => (
          <SwiperSlide key={i}>
            <Slide
              productId={s?.id}
              first={i === 0}
              category={s?.attributes?.main_category?.data?.attributes}
              subcategory={s?.attributes?.categories?.data[0]?.attributes}
              basePrice={s?.attributes?.baseprice}
              discountPrice={s?.attributes?.discountprice}
              image={
                urlPrefix + s?.attributes?.thumbnail?.data?.attributes?.url
              }
              title={s?.attributes?.title}
              subtitle={s?.attributes?.subtitle}
              discount={s?.attributes?.discount}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Slider;
