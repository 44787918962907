import axios from "axios";


const url =
process.env.NODE_ENV === "production"
? process.env.REACT_APP_BACKEND_URL
: "http://localhost:1337";

const urlPrefix = process.env.NODE_ENV === "production" ? "" : url;

export { url, urlPrefix };

export default axios.create({
  baseURL: url,
});
