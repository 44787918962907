import classes from "./AddToCart.module.css";

const AddToCart = (props) => (
  <div className={classes.AddToCart}>
    <button>In den Warenkorb</button>
    <input
      onChange={(e) => props.setAmount(e.target.value)}
      value={props.amount}
      type="text"
    />
    <div>
      <span onClick={() => props.setAmount(props.amount + 1)}>+</span>
      <span onClick={() => props.setAmount(props.amount - 1)}>-</span>
    </div>
  </div>
);

export default AddToCart;
